$(() => {
  $('input[name="user[drinking_area_ids][]"]').on('change', function() {
    if ($(this).prop('checked')){
      $("[name='user[drinking_area_ids][]']").prop('checked', false);
      $(this).prop('checked', true);
    }

    if($('input[name="user[drinking_area_ids][]"]:checked').length > 0) {
      $('#drinking_area_form_submit').prop("disabled", false);
    } else {
      $('#drinking_area_form_submit').prop("disabled", true);
    }
  });
});
